/* @import url('https://fonts.googleapis.com/css?family=Permanent+Marker|Rubik|Shadows+Into+Light|Shrikhand|Staatliches|Gloria+Hallelujah|B612+Mono|Indie+Flower|Kalam|Kaushan+Script|Play|Raleway|Righteous|Titillium+Web|Ubuntu'); */
/* @import url('https://fonts.googleapis.com/css?family=Righteous'); */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  /* height: 100vh; */
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font--righteous {
	font-family: 'Righteous', cursive;
}

/* .font--permanent-marker {
  font-family: 'Permanent Marker', cursive;
}
.font--shrikhand {
  font-family: 'Shrikhand', cursive;
}
.font--staatliches {
  font-family: 'Staatliches', cursive;
}
.font--rubik {
  font-family: 'Rubik', cursive;
}
.font--indie-flower {
  font-family: 'Indie Flower', cursive;
}
.font--shadows-into-light {
  font-family: 'Shadows Into Light', cursive;
}
.font--gloria-hallelujah {
  font-family: 'Gloria Hallelujah', cursive;
}

.font--raleway {
	font-family: 'Raleway', sans-serif;
}

.font--titillium-web {
	font-family: 'Titillium Web', sans-serif;
}

.font--ubuntu {
	font-family: 'Ubuntu', sans-serif;
}

.font--b612-mono {
	font-family: 'B612 Mono', monospace;
}

.font--play {
	font-family: 'Play', sans-serif;
}

.font--kaushan-script {
	font-family: 'Kaushan Script', cursive;
}

.font--kalam {
	font-family: 'Kalam', cursive;
} */

