footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: #58595a;
  font-size: calc(5px + 1vmin);
}

footer :-webkit-any-link, footer :any-link {
  color: inherit;
  text-decoration: inherit;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 60vh;
  position: relative;
  top: -15vh;
  z-index: 1;
  opacity: 0.5;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-top: 20vmin;
}
.App-header>h1{
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

.App-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

ul{
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  place-self: stretch;
  list-style-type: none;
  max-width: 80vmax;
  margin: 0 auto;
  width: 100%;
  margin-block-end: 0;
  padding-inline-start: 0;
}

ul>li{
  display: flex;
  white-space: nowrap;
  color: #58595a;
  cursor: default;
}

ul>li.separator {
  color: #343536
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes floating-text-fadein {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 0.8;
    display: initial;
  }
}

.floating-text {
  font-weight: bold;
  opacity: 0;
  display: none;
  color: #c09341;
}

.show {
  animation: floating-text-fadein ease-in 500ms;
  display: initial;
  opacity: 0.8;
}

